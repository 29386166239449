
import PerkCard from 'components/simple/perk-card.vue'
import { defineComponent } from 'vue'
import PageTemplate from 'components/template/page-template.vue'
import { useI18n$ } from 'boot/i18n'
import { QBtn } from 'quasar'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import usePageExtensions from 'src/compositions/pageExtensions'

const name = 'perks'

export default defineComponent({
  name,
  components: {
    PerkCard,
    QBtn,
    PageTemplate,
    Breadcrumbs
  },
  setup() {
    const { t } = useI18n$(name)

    const perks = [
      {
        name: 'eSim square',
        description: () => t('perks.eSim-square.description'),
        logoPath: 'images/perks/esim.svg',
        imagePath: 'images/perks/esim.png',
        discount: '20%',
        validUntil: '12.12.2024',
        promoCode: 'Qeepl',
        offerLink: 'https://esim.telecomsquare.net/?affiliateId=13&utm_source=Qeepl&utm_medium=affiliate&utm_campaign=none'
      }
    ]

    return {
      perks,
      t,
      ...usePageExtensions({
        page: name,
        breadcrumbs: () => ([{
          path: 'perks',
          label: t('perks.title')
        }])
      })
    }
  }
})
