
import { computed, defineAsyncComponent, defineComponent } from 'vue'
import useRoutes from 'src/compositions/routes'
import useDrawer from 'src/compositions/drawer'
import { useI18n$ } from 'boot/i18n'
import ToolbarTitle from 'components/simple/page/toolbar-title.vue'
import LanguageSelector from 'src/components/advanced/language-selector.vue'
import AuthorizationButton from 'src/components/advanced/authorization-button.vue'
import ContainerTemplate from 'components/template/container-template.vue'
import PageLinks from 'components/simple/page/page-links.vue'
import { QBtn, QHeader, QNoSsr, QToolbar } from 'quasar'
import useUserDetails from 'stores/userDetails'
import { storeToRefs } from 'pinia'

const name = 'page-header'

const pages = ['blog', 'reviews', 'contact', 'perks']
const matMenu = 'M0 0h24v24H0z@@fill:none;&&M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'

export default defineComponent({
  name,
  components: {
    QHeader,
    QToolbar,
    QBtn,
    QNoSsr,
    ContainerTemplate,
    ToolbarTitle,
    LanguageSelector,
    AuthorizationButton,
    PageLinks,
    AdminBtn: defineAsyncComponent(() => import(/* webpackChunkName: "admin-btn", webpackMode: "lazy" */'components/simple/page/admin-btn.vue'))
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  setup () {
    const {
      isAdmin,
      isPartner,
      isSecondaryAdmin,
      isAnonymous
    } = storeToRefs(useUserDetails())
    const { toPage } = useRoutes()
    const { toggle } = useDrawer()
    const { tp } = useI18n$('global.pages')

    const anonymousPages = computed(() => isAnonymous.value ? pages : [...pages, 'partners'])

    return {
      matMenu,
      anonymousPages,
      isPartner,
      isAdmin,
      isSecondaryAdmin,
      toPage,
      toggle,
      tp
    }
  }
})
