
import { getAppStoreLink } from 'src/functions/utils'
import { defineComponent } from 'vue'
import BannerTemplate from 'components/simple/home-page-template/banner-template.vue'
import { GOOGLE_PLAY_LINK } from 'src/model/constants'
import { useI18n$ } from 'boot/i18n'
import { QBtn } from 'quasar'

const name = 'app-links-banner'

export default defineComponent({
  name,
  components: {
    QBtn,
    BannerTemplate,
  },
  props: {
    alt: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { tp, language } = useI18n$(name)

    return {
      GOOGLE_PLAY_LINK,
      tp,
      language,
      getAppStoreLink
    }
  }
})
