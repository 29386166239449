import { ref } from 'vue'
import { defineStore } from 'pinia'
import { AutocompletePoint, Coordinates, Location } from 'src/model/common.model'
import { DEFAULT_COORDINATES, DEFAULT_COUNTRY_KEY } from 'src/model/constants'
import useAnonymousApi from 'src/api/anonymous.api'
import { getGPSLocation } from 'src/functions/bridge'
import { Language } from 'src/model/language.model'

export interface InitialLocation {
  point: Coordinates
  countryKey: string
}

export enum LocationType {
  SEARCH, GPS, GEO, DEFAULT
}

const useUserLocation = defineStore('userLocation', () => {

  const api = useAnonymousApi()

  const initialLocation = ref<InitialLocation>({
    countryKey: DEFAULT_COUNTRY_KEY,
    point: DEFAULT_COORDINATES
  })
  const location = ref<Location>({ point: DEFAULT_COORDINATES })
  const locationType = ref<LocationType>(LocationType.DEFAULT)
  const lastUpdatedByUser = ref(false)
  const locationText = ref('')

  const setInitialLocation = (newInitialLocation: InitialLocation) => {
    initialLocation.value = newInitialLocation
    setLocation(
      {
        point: newInitialLocation.point,
      },
      LocationType.DEFAULT
    )
  }
  const setLocation = (newLocation: Location | null, newLocationType: LocationType = locationType.value, updatedByUser: boolean = false) => {
    if (updatedByUser) {
      lastUpdatedByUser.value = true
    }
    if (lastUpdatedByUser.value === updatedByUser) {
      if (newLocation) {
        const oldLocation = location.value
        if (newLocation?.point.latitude !== oldLocation?.point.latitude || newLocation?.point.longitude !== oldLocation?.point.longitude) {
          location.value = newLocation
        }
      } else {
        location.value = { point: DEFAULT_COORDINATES }
      }
      locationType.value = newLocationType
    }
  }
  const setLocationText = (text: string) => {
    locationText.value = text
  }

  const setLocationType = (type: LocationType) => {
    locationType.value = type
  }

  const resolveGpsLocationAndSet = (language: Language): Promise<boolean> => {
    return getGPSLocation()
      .then(newLocation => {
        if (newLocation && newLocation !== location.value) {
          setLocation(newLocation, LocationType.GPS, true)
          return resolveLocationText(location.value, language)
            .then(() => true)
        }
        return false
      })
      .catch(() => false)
  }

  const resolveLocationText = (location: Location | null, language: Language) => {
    const locationPromise = location ? api.geocodeReverse(location.point, language, 'address')
      .then(place => place?.address || '') : Promise.resolve('')
    return locationPromise
      .then(text => locationText.value = text)
      .catch(() => locationText.value = location?.description || '')
  }

  return {
    initialLocation,
    location,
    locationText,
    locationType,
    setInitialLocation,
    setLocation,
    setLocationText,
    setLocationType,
    resolveGpsLocation: getGPSLocation,
    resolveGpsLocationAndSet,
    resolveLocationText
  }
})

export default useUserLocation
