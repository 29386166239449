import { InjectionKey } from 'vue'
import {
  AuthorizeRequest,
  AuthorizeUserByFirebaseRequest,
  JwtResponse,
  RegisterAndCompletePaymentResponse,
  RegisterAndCompleteRequest,
  RegisterPartnerUserRequest,
  RegisterUserByStripeRequest
} from 'src/api/dto/user'
import { UserProjection } from 'src/model/user.model'
import { Language } from 'src/model/language.model'
import { Attraction } from 'src/model/attraction.model'
import { Content, ContentLandingName, ContentType } from 'src/model/content.model'
import {
  Amount,
  AutocompletePoint,
  Container,
  Coordinates,
  Location,
  CoordinatesInfo,
  LookupResult,
  OtpMethod,
  Page,
  Pageable,
  Path,
  PaymentSystem,
  QueryParams,
  ServiceType,
  ServiceTypeAlias,
  TotalReview
} from 'src/model/common.model'
import { Discount } from 'src/model/discount.model'
import { ReviewProjection } from 'src/model/review.model'
import { SendCodeRequest, VerifyCodeRequest } from 'src/api/dto/sms'
import { GetTotalPriceRequest, ItemsConfigurationResponse } from 'src/api/dto/order'
import {
  CompaniesRequest,
  CompaniesResponse,
  CompanyActivityResponse,
  GeographyResponse,
  ReviewsResponse
} from 'src/api/dto/prefetch'
import { getActivePinia, Pinia } from 'pinia'
import { injectSafe } from 'src/compositions/common'
import { keyToPath } from 'src/functions/utils'
import { AxiosCallback } from 'boot/common/axios'
import { City } from 'src/model/city.model'
import { Country } from 'src/model/country.model'
import { AxiosResponse } from 'axios'
import { DEFAULT_CITY_KEY, DEFAULT_COUNTRY_KEY } from 'src/model/constants'
import { CityAdsRequest } from 'src/api/dto/ads'
import { CHARGE_TYPE_TO_ORDER_ITEM, ORDER_ITEM_SIZES } from 'src/functions/order'

const anonymousApiKey: InjectionKey<ReturnType<typeof useAnonymousApi_>> = Symbol('qeepl_anonymousApi')

function tokenConfig(token: string) {
  return { headers: { 'X-Token': token } }
}

function useAnonymousApi_(store: Pinia) {
  const { self } = store.api

  let sessionId = ''

  function getSessionId(): string {
    if (sessionId) {
      return sessionId
    }
    sessionId = Math.random().toString(36).substring(2, 15)
    return sessionId
  }

  // account
  async function authorize(request: AuthorizeRequest): Promise<JwtResponse> {
    return self.post('/anonymous/authorize', request)
      .then(res => res.data)
  }

  async function getAccount(): Promise<UserProjection> {
    return self.get('/anonymous/account')
      .then(res => res.data)
  }

  async function authorizeByFirebase(request: AuthorizeUserByFirebaseRequest, token: string): Promise<JwtResponse> {
    return self.post('/anonymous/authorize-by-firebase', request, tokenConfig(token))
      .then(res => res.data)
  }

  async function registerPartner(request: RegisterPartnerUserRequest, token: string, referredBy?: string): Promise<JwtResponse> {
    return self.post(`/anonymous/register-partner${ referredBy ? `?referredBy=${ referredBy }` : '' }`, request, tokenConfig(token))
      .then(res => res.data)
  }

  async function logout(): Promise<void> {
    return self.get('/anonymous/logout')
      .then(res => res.data)
      .catch(() => {
      })
  }

  //otp
  async function sendCode(login: string, method: OtpMethod, language: Language, captcha?: string): Promise<void> {
    const request: SendCodeRequest = {
      login,
      method,
      language,
      captcha
    }
    return self.post('/anonymous/otp/send', request)
  }

  async function verifyCode(login: string, method: OtpMethod, code: string): Promise<JwtResponse> {
    const request: VerifyCodeRequest = {
      login,
      method,
      code
    }
    return self.post('/anonymous/otp/verify', request)
      .then(res => res.data)
  }

  //prefetch
  async function getGeography(callback?: AxiosCallback<GeographyResponse>): Promise<GeographyResponse> {
    return self.get('/anonymous/geography', { callback })
      .then(res => res.data)
      .catch(() => ({
        cities: [],
        countries: []
      }))
  }

  async function getAttractions(key: string, callback?: AxiosCallback<Attraction[]>): Promise<Attraction[]> {
    return self.get(`/anonymous/city/${ key }/attractions`, { callback })
      .then(res => res.data)
      .catch(() => [])
  }

  async function getAttraction(key: string, callback?: AxiosCallback<Attraction>): Promise<Attraction> {
    return self.get(`/anonymous/attractions/${ key }`, { callback })
      .then(res => res.data)
  }

  async function getCompanies(request: CompaniesRequest, callback?: AxiosCallback<CompaniesResponse>): Promise<CompaniesResponse> {
    const params = {
      companyId: request.companyId,
      city: request.cityKey,
      attraction: request.attractionKey,
      latitude: request.point?.latitude,
      longitude: request.point?.longitude
    }
    //@ts-ignore
    return self.get(`/anonymous/companies${ QueryParams.of(params) }`, { callback })
      .then(res => res.data)
      .catch(() => ({
        country: null,
        city: null,
        companies: []
      }))
  }

  async function getAppReviews(language: Language, callback?: AxiosCallback<ReviewsResponse>): Promise<ReviewsResponse> {
    return self.get(`/anonymous/reviews/${ language }/app`, { callback })
      .then(res => res.data)
      .catch(() => ({
        reviews: [],
        totalReview: {
          count: 0,
          value: 5
        }
      }))
  }

  async function getContent(contentType: ContentType, path: string, queryParams?: Container<string>, callback?: AxiosCallback<Content>): Promise<Content> {
    return self.get(`/anonymous/contents/${ contentType }/${ path }/one${ QueryParams.of(queryParams) }`, { callback })
      .then(res => res.data)
  }

  async function getLandingContent(key: string, name: ContentLandingName, alias: ServiceTypeAlias, callback?: AxiosCallback<Content>): Promise<Content> {
    const params: Container<string> = {
      name,
      alias
    }
    return getContent('LANDING', keyToPath(key), params, callback)
      .catch(() => ({
        path: '',
        type: 'LANDING',
        body: {
          en: ''
        },
        landing: {
          country: DEFAULT_COUNTRY_KEY,
          city: DEFAULT_CITY_KEY,
          serviceTypeAlias: 'LUGGAGE',
          name: 'CITY'
        },
        createdDate: '',
      }))
  }

  async function getCityContent(key: string, serviceTypeAlias: ServiceTypeAlias, callback?: AxiosCallback<Content>): Promise<Content> {
    return getLandingContent(key, 'CITY', serviceTypeAlias, callback)
  }

  async function getAttractionContent(key: string, serviceTypeAlias: ServiceTypeAlias, callback?: AxiosCallback<Content>): Promise<Content> {
    return getLandingContent(key, 'ATTRACTION', serviceTypeAlias, callback)
  }

  async function getBlogContent(path: string): Promise<Content> {
    return getContent('BLOG', path)
  }

  async function getTerms(client: boolean = true, country?: string): Promise<Content> {
    const name = `terms${ client ? '' : '-partners' }${ country ? `-${ country.toLowerCase() }` : '' }`
    return getContent('REQUIRED', name)
  }

  async function getPolicy(country?: string): Promise<Content> {
    const name = `policy${ country ? `-${ country.toLowerCase() }` : '' }`
    return getContent('REQUIRED', name)
  }

  async function getContents(contentType: ContentType, language: Language, pageable?: Pageable): Promise<Page<Content>> {
    const pageableParams = Pageable.map(pageable)
    return self.get(`/anonymous/contents/${ contentType }/${ language }/all${ pageableParams }`)
      .then(res => Page.of<Content>(res.data, pageable))
      .catch(() => Page.empty())
  }

  async function getBlogList(language: Language, pageable?: Pageable): Promise<Page<Content>> {
    return getContents('BLOG', language, pageable)
  }

  async function getBagsCount(callback?: AxiosCallback<number>): Promise<number> {
    return self.get(`/anonymous/orders/bags-count`, {
      responseType: 'text',
      callback
    })
      .then(res => res.data)
      .catch(() => 32123)
  }

  async function getUsersCount(callback?: AxiosCallback<number>): Promise<number> {
    return self.get(`/anonymous/users/count`, {
      responseType: 'text',
      callback
    })
      .then(res => res.data)
      .catch(() => 35362)
  }

  async function getPartnersCount(callback?: AxiosCallback<number>): Promise<number> {
    return self.get(`/anonymous/partners/count`, {
      responseType: 'text',
      callback
    })
      .then(res => res.data)
      .catch(() => 217)
  }

  async function getPartnerReviews(language: Language, callback?: AxiosCallback<ReviewProjection[]>): Promise<ReviewProjection[]> {
    return self.get(`/anonymous/reviews/${ language }/partners`, { callback })
      .then(res => res.data)
      .catch(() => ([]))
  }

  //secondary
  async function getDiscount(name: string): Promise<Discount> {
    return self.post('/anonymous/discount', { name })
      .then(res => res.data)
  }

  async function getTotalPrice(request: GetTotalPriceRequest, type: ServiceType): Promise<Amount> {
    return self.post(`/anonymous/orders/price?type=${ type }`, request)
      .then(res => res.data)
  }

  async function getReviewsByLanguage(language: Language, pageable?: Pageable): Promise<Page<ReviewProjection>> {
    return self.get(`/anonymous/reviews/${ language }/all${ Pageable.map(pageable) }`)
      .then(res => Page.of(res.data, pageable) as Page<ReviewProjection>)
      .catch(() => Page.empty() as Page<ReviewProjection>)
  }

  async function getTotalCompanyReviews(companyId: string): Promise<TotalReview> {
    return self.get(`/anonymous/companies/${ companyId }/reviews/total`)
      .then(res => res.data)
  }

  async function getReviewsByCompany(companyId: string, pageable?: Pageable): Promise<Page<ReviewProjection>> {
    return self.get(`/anonymous/companies/${ companyId }/reviews${ Pageable.map(pageable) }`)
      .then(res => Page.of(res.data, pageable))
  }

  async function getCompanyHourPrice(companyId: string): Promise<Amount> {
    return self.get(`/anonymous/companies/${ companyId }/hour-price`)
      .then(res => res.data)
  }

  async function getCompanyOccupiedCount(companyId: string, startDate: string, endDate: string): Promise<number> {
    const queryParams = {
      companyId,
      startDate,
      endDate
    }
    return self.get(`/anonymous/companies/occupied-count${ QueryParams.of(queryParams) }`, { responseType: 'text' })
      .then(res => res.data)
  }

  async function getCompanyActivity(companyId: string): Promise<CompanyActivityResponse> {
    return self.get(`/anonymous/companies/${ companyId }/activity`)
      .then(res => res.data)
  }

  async function getCountry(id: string, callback?: AxiosCallback<Country>): Promise<Country> {
    return self.get(`/anonymous/country/${ id }`, { callback })
      .then(res => res.data)
  }

  async function getCity(id: string, callback?: AxiosCallback<City>): Promise<City> {
    return self.get(`/anonymous/city/${ id }`, { callback })
      .then(res => res.data)
  }

  async function getItemsConfiguration(): Promise<ItemsConfigurationResponse> {
    return self.get(`/anonymous/config/items`)
      .then(res => res.data)
      .catch(() => {
        return { chargeTypes: CHARGE_TYPE_TO_ORDER_ITEM, itemSizes: ORDER_ITEM_SIZES }
      })
  }

  async function registerUserByStripe(request: RegisterUserByStripeRequest): Promise<JwtResponse> {
    return self.post('/anonymous/register-user-by-stripe', request)
      .then(res => res.data)
  }

  async function registerAndCompletePayment(request: RegisterAndCompleteRequest): Promise<RegisterAndCompletePaymentResponse> {
    return self.post(`/anonymous/register-and-complete-payment`, request)
      .then(res => res.data)
  }

  async function loadMerchantId(countryKey: string, paymentSystem: PaymentSystem): Promise<any> {
    return self.get(`/anonymous/payment/merchant/${ countryKey }/${ paymentSystem }`)
      .then(res => res.data)
  }

  async function refreshPasswordInit(email: string, token: string): Promise<void> {
    return self.post(`/anonymous/account/reset-password/init`, { email }, tokenConfig(token))
      .then(res => res.data)
  }

  async function refreshPasswordFinish(key: string, newPassword: string, token: string): Promise<void> {
    return self.post(`/anonymous/account/reset-password/finish`, { key, password: newPassword }, tokenConfig(token))
      .then(res => res.data)
  }

  async function checkPayment(orderId: string): Promise<boolean> {
    return self.get(`/anonymous/payment/check/${ orderId }`)
      .then(response => true)
      .catch(() => false)
  }

  async function createApplePaySession(validationUrl: string): Promise<any> {
    return self.get(`/anonymous/payment/create-apple-pay-session?validationUrl=${ validationUrl }`)
      .then(res => res.data)
  }

  async function translate(text: string, to: Language, from?: Language): Promise<string> {
    return self.get(`/anonymous/translate/${ text }?to=${ to }${ from ? `&from=${ from }` : '' }`, { responseType: 'text' })
      .then(res => res.data)
      .catch(() => '')
  }

  // other api
  async function autocompleteLocations(query: string, language: Language): Promise<AutocompletePoint[]> {
    return self.get(`/_self/autocomplete?query=${ query }&lang=${ language }&sessionId=${ getSessionId() }`)
      .then(response => {
        const data = response.data
        if (data.status === 'OK') {
          return (data.predictions || []).map((p: any) => {
            return {
              description: p.description,
              pointId: p.place_id
            }
          })
        }
        return []
      })
  }

  async function searchPlace(placeId: string, language: Language): Promise<Coordinates | null> {
    return self.get(`/_self/search?placeId=${ placeId }&lang=${ language }&sessionId=${ getSessionId() }`)
      .then(response => {
        const data = response.data
        if (data.status === 'OK') {
          const location = data.result?.geometry?.location
          return location ? {
            latitude: location.lat,
            longitude: location.lng,
          } : null
        }
        return null
      })
  }

  async function geocodeReverse(coordinates: Coordinates, language: Language, result: 'address' | 'country' = 'country'): Promise<CoordinatesInfo | null> {
    const searchResult = `country|locality${ result === 'address' ? '|street_address' : '' }`
    return self.get(`/_self/reverse?latitude=${ coordinates.latitude }&longitude=${ coordinates.longitude }&lang=${ language }&sessionId=${ getSessionId() }&fields=${ searchResult }`)
      .then((response: any) => {
        const data = response.data
        if (data.status === 'OK' && data.results?.length > 0) {
          const address = data.results[0].formatted_address
          const components = data.results[0].address_components

          function extractName(types: string[], type: 'short_name' | 'long_name'): string {
            const foundComponent = components?.find((ad: any) => ad.types.filter((t: string) => types.includes(t)).length > 0)
            return foundComponent?.[type] || ''
          }

          return {
            address,
            countryKey: extractName(['country'], 'short_name'),
            city: extractName(['locality', 'postal_town', 'administrative_area_level_1'], 'long_name'),
          }
        }
        return null
      })
      .catch(() => null)
  }

  // async function closestPlaces(coordinates: Coordinates, language: Language): Promise<DistanceTo[]> {
  //   return self.get(`/_self/maps/closest-places?latitude=${ coordinates.latitude }&longitude=${ coordinates.longitude }&lang=${ language }&sessionId=${ getSessionId() }`)
  //     .then((response: any) => {
  //       const data = response.data
  //       if (data.status === 'OK' && data.results?.length > 0) {
  //         const results = data.results
  //         results.sort((r1: any, r2: any) => r2.user_ratings_total - r1.user_ratings_total)
  //         const result = results.map((r: any) => {
  //           const geometry = r.geometry
  //           return {
  //             name: r.name,
  //             value: computeDirectDestination(coordinates, {
  //               latitude: geometry.location.lat,
  //               longitude: geometry.location.lng
  //             }),
  //           }
  //         })
  //         return result.slice(0, 3)
  //       }
  //       return null
  //     })
  //     .catch(() => [])
  // }

  function createAppleMapsFunctions() {
    let appleMapsToken = ''
    let appleMapsApiToken = ''
    let appleMapsApiTokenExpire = new Date().getTime()

    async function getMapsToken(): Promise<string> {
      if (appleMapsToken) {
        return appleMapsToken
      }
      return self.get('/_self/maps/token', { responseType: 'text' })
        .then((response: AxiosResponse<string>) => {
          appleMapsToken = response.data
          return appleMapsToken
        })
    }

    async function getAppleMapsApiToken(): Promise<string> {
      if (appleMapsApiToken && new Date().getTime() < appleMapsApiTokenExpire) {
        return appleMapsApiToken
      }
      return self.get('/_self/maps/api-token')
        .then(res => res.data)
        .then((data: { accessToken: string, expiresInSeconds: number }) => {
          appleMapsApiToken = data.accessToken
          appleMapsApiTokenExpire = new Date().getTime() + ((data.expiresInSeconds - 1) * 1000)
          return appleMapsApiToken
        })
    }

    async function autocomplete(query: string, language: Language, location: Location): Promise<AutocompletePoint[]> {
      const apiKey = await getAppleMapsApiToken()
      return self.get(`/_self/maps/autocomplete?query=${ query }&lang=${ language }&lat=${ location.point.latitude }&lon=${ location.point.longitude }&apiKey=${ apiKey }`)
        .then(response => {
          const data = response.data
          const results = (data.results || []).map((r: any) => {
            return {
              description: r.displayLines?.[0] || '',
              caption: r.displayLines?.[1] || undefined,
              coordinates: {
                latitude: r.location.lat,
                longitude: r.location.lng,
              }
            }
          })
            .filter((r: any) => !!r.description)
          return Array.from<AutocompletePoint>(new Set(results)).slice(0, 5)
        })
    }

    async function route(point1: Coordinates, point2: Coordinates): Promise<Path | null> {
      const apiKey = await getAppleMapsApiToken()
      return self.get(`/_self/maps/route?origin=${ point1.latitude },${ point1.longitude }&destination=${ point2.latitude },${ point2.longitude }&apiKey=${ apiKey }`)
        .then(res => {
          const stepPaths = res.data.stepPaths
          const steps = res.data.steps
          if (stepPaths?.length && steps?.length) {
            const resultPoints: Coordinates[] = []
            let distance = 0
            stepPaths.forEach((paths: any) => {
              resultPoints.push(...paths.map((p: any) => ({ latitude: p.lat, longitude: p.lng })))
            })
            steps.forEach((s: any) => {
              distance += s.distanceMeters || 0
            })
            return {
              from: point1,
              to: point2,
              points: resultPoints,
              distance: distance
            }
          }
          return null
        })
        .catch(err => {
          console.error(JSON.stringify(err))
          return null
        })
    }

    async function lookup(
      address: string,
      language: Language,
      city?: string,
      country?: string
    ): Promise<LookupResult[]> {
      const apiKey = await getAppleMapsApiToken()
      const lookupAddress = `${ address }${ city ? `, ${ city }` : '' }${ country ? `, ${ country }` : '' }`
      return self.get(`/_self/maps/lookup?address=${ lookupAddress }&lang=${ language }&apiKey=${ apiKey }`)
        .then(res => {
          const data = res.data
          if (data?.results?.length) {
            return data.results.map((res: any) => {
              return {
                coordinates: {
                  latitude: res.center.lat,
                  longitude: res.center.lng,
                },
                address: res.formattedAddressLines.join(', '),
                city: res.locality,
                countryKey: res.countryCode
              }
            })
          }
          return []
        })
        .catch(err => {
          console.error(JSON.stringify(err))
          return []
        })
    }

    return {
      getMapsToken,
      autocomplete,
      route,
      lookup
    }
  }

  async function postCityAdsEvent(request: CityAdsRequest): Promise<void> {
    const { clickId, orderId, price, status } = request
    return self.get(`/_self/ads/cityads?clickId=${ clickId }&orderId=${ orderId }&price=${ price.value }&currency=${ price.currency }&status=${ status }`)
      .then(res => console.info('Post city ads request is successful', request))
      .catch(() => console.warn('Could not post city ads request', request))
  }

  return {
    authorize,
    getAccount,
    authorizeByFirebase,
    registerPartner,
    logout,
    sendCode,
    verifyCode,
    getGeography,
    getAttractions,
    getAttraction,
    getCityContent,
    getAttractionContent,
    getBlogList,
    getBlogContent,
    getTerms,
    getPolicy,
    getCompanies,
    getBagsCount,
    getUsersCount,
    getPartnersCount,
    getDiscount,
    getTotalPrice,
    getReviewsByLanguage,
    getReviewsByCompany,
    getCompanyHourPrice,
    getCompanyOccupiedCount,
    getCompanyActivity,
    getCountry,
    getCity,
    getItemsConfiguration,
    registerUserByStripe,
    registerAndCompletePayment,
    loadMerchantId,
    createApplePaySession,
    getAppReviews,
    getPartnerReviews,
    getTotalCompanyReviews,
    checkPayment,
    translate,
    refreshPasswordInit,
    refreshPasswordFinish,
    autocompleteLocations,
    searchPlace,
    geocodeReverse,
    ...createAppleMapsFunctions(),
    postCityAdsEvent
  }
}

export default function useAnonymousApi() {
  return injectSafe(anonymousApiKey, () => useAnonymousApi_(getActivePinia()!))
}
