
import { computed, defineComponent, nextTick, PropType, Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import { AutocompletePoint } from 'src/model/common.model'
import useAnonymousApi from 'src/api/anonymous.api'
import { useI18n$ } from 'boot/i18n'
import DateTimeButton from 'src/components/advanced/date-time-button.vue'
import BagsButton from 'src/components/advanced/bags-button.vue'
import LuggageButton from 'src/components/advanced/luggage-button.vue'
import IconBtn from 'components/simple/icon-btn.vue'
import { QIcon, QItem, QItemLabel, QItemSection, QSelect, QSeparator } from 'quasar'
import useSelect from 'src/compositions/select'
import { refStore } from 'stores/__common'
import useUserLocation, { LocationType } from 'stores/userLocation'
import useRoutes from 'src/compositions/routes'
import { CountryProjection } from 'src/model/country.model'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'
import { provideUserOrder } from 'src/compositions/user/userOrderCommon'
import useUserOrder from 'src/compositions/user/userOrder'
import {
  COMPANIES_MAP,
  COMPANIES_MAP__OPEN,
  COMPANIES_MAP__OPEN__BOOK,
  COMPANIES_MAP__OPEN__BOOK__PAY
} from 'pages/names'

const name = 'location-select'

interface CityLocation extends AutocompletePoint {
  key: string
}

export default defineComponent({
  components: {
    QSelect,
    QItem,
    QItemSection,
    QItemLabel,
    QIcon,
    QSeparator,
    DateTimeButton,
    BagsButton,
    IconBtn,
    LuggageButton
  },
  name,
  props: {
    noDatesChooser: {
      type: Boolean,
      default: false
    },
    noBagsChooser: {
      type: Boolean,
      default: false
    },
    noLeaveButton: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    query: {
      type: String,
      default: ''
    },
    onClick: {
      type: Function as PropType<() => void>,
      default: () => {
      }
    }
  },
  setup() {
    const { autocomplete: autocompleteLocations, searchPlace } = useAnonymousApi()
    const {
      locationText,
      setLocationText,
      setLocationType,
      setLocation,
      location
    } = refStore(useUserLocation())
    const {
      language,
      t,
      tp,
      st
    } = useI18n$(name)
    const { toMap } = useRoutes()
    const { push, currentRoute } = useRouter()
    const {
      main5Cities,
      loadGeography,
      allCountries,
      cities,
      city,
      country,
      loadAllByCity,
      loadAllNearest,
    } = useUserPreviewExtension()
    const {
      dateRange,
      timeRange,
      itemsMetadata,
      orderLength,
      company,
      hourly,
      chargeType,
      setDateTime,
      setItemsMetadata,
      setOrderLength,
      setHourly,
      setChargeType
    } = useUserOrder()

    provideUserOrder(dateRange, timeRange, itemsMetadata, orderLength, company, hourly, chargeType, setDateTime, setItemsMetadata, setOrderLength, setHourly, setChargeType)

    const {
      options,
      filterOptions,
      loadOptionsOccurred
    } = useSelect<AutocompletePoint | CityLocation>({
      filterOptions: (text) => text && text.length > 2 ? autocompleteLocations(text, language.value, location.value) : initOptions(),
      minTextFilterCount: 0
    })

    const selectRef = ref(null) as unknown as Ref<QSelect>

    const defaultOptions = computed<CityLocation[]>(() => {
      if (!cities.value.length) {
        return []
      }
      return main5Cities.value.map(city => {
        const countryName = st(allCountries.value.find((country: CountryProjection) => country.key === city.country)!.name)
        return {
          description: st(city.name),
          caption: countryName,
          key: city.key,
        }
      })
    })

    const onSelect = async (point: AutocompletePoint | CityLocation | null) => {
      if (!point) {
        return
      }
      if ('key' in point) {
        await push(toMap(`city=${ point.key }`))
        if ([COMPANIES_MAP, COMPANIES_MAP__OPEN, COMPANIES_MAP__OPEN__BOOK, COMPANIES_MAP__OPEN__BOOK__PAY].includes(currentRoute.value.name as string)) {
          await loadAllByCity(point.key)
          setLocation({ point: city.value!.center }, LocationType.SEARCH, true)
        }
      } else {
        let coordinates
        if (point.coordinates) {
          coordinates = point.coordinates
        } else {
          coordinates = await searchPlace(point.pointId!, language.value)
        }
        if (coordinates) {
          await loadAllNearest({ point: coordinates })
          setLocation({ point: coordinates }, LocationType.SEARCH, true)
        }
        setLocationText(point.description)
      }
      await nextTick(() => selectRef.value?.blur())
    }

    const initOptions = () => {
      return loadGeography()
        .then(() => defaultOptions.value)
    }
    const onInput = (input: string) => {
      setLocationText(input)
    }
    const onShow = () => nextTick(() => {
      const input = document.querySelector('.q-select__dialog input') as HTMLInputElement | undefined
      input?.focus()
    })

    const onClear = () => {
      onInput('')
      selectRef.value?.updateInputValue('')
      onShow()
    }

    return {
      selectRef,
      modelValue: locationText,
      options,
      loadOptionsOccurred,
      defaultOptions,
      country,
      city,
      t,
      tp,
      filterOptions,
      onSelect,
      onInput,
      onShow,
      onClear,
    }
  }
})
