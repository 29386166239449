export const INDEX = 'index'
export const INDEX_LUGGAGE = 'index-luggage'
export const INDEX_SELF = 'index-self'
export const INDEX_ALIAS = 'index-alias'
export const CITY_LUGGAGE = 'city-luggage'
export const CITY_SELF = 'city-self'
export const CITY_ALIAS = 'city-alias'
export const ATTRACTION_LUGGAGE = 'attraction-luggage'
export const ATTRACTION_SELF = 'attraction-self'
export const ATTRACTION_ALIAS = 'attraction-alias'
export const BLOG_LIST = 'blog-list'
export const BLOG = 'blog'
export const TERMS = 'terms'
export const POLICY = 'policy'
export const PROFILE = 'profile'
export const LOGIN = 'login'
export const RECOVERY = 'recovery'
export const PARTNERS = 'partners'
export const ONBOARDING = 'onboarding'
export const CABINET = 'cabinet'
export const REVIEWS = 'reviews'
export const CONTACT = 'contact'
export const CABINET__BOOKINGS = 'cabinet__bookings'
export const CABINET__REPORTS = 'cabinet__reports'
export const CABINET__LOCATIONS = 'cabinet__locations'
export const CABINET__PAYOUT_SETTINGS = 'cabinet__payout-settings'
export const CABINET__INSTRUCTION = 'cabinet__instruction'
export const COMPANIES_MAP = 'companies-map'
export const COMPANIES_MAP__OPEN = 'companies-map__open'
export const COMPANIES_MAP__OPEN__BOOK = 'companies-map__open__book'
export const COMPANIES_MAP__OPEN__BOOK__PAY = 'companies-map__open__book__pay'
export const MY_ORDERS = 'my-orders'
export const MY_ORDERS__OPEN = 'my-orders__open'
export const MY_ORDERS__OPEN__INSTRUCTION = 'my-orders__open__instruction'
export const MY_ORDERS__OPEN__EDIT = 'my-orders__open__edit'
export const PAYMENT__SUCCESS = 'payment__success'
export const PERKS = 'perks'
export const ERROR_404 = 'error404'
