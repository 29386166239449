
import { computed, defineComponent, PropType, toRef } from 'vue'
import { useI18n$ } from 'boot/i18n'
import { QBtn, QIcon } from 'quasar'
import { VueClick } from 'components/models'
import { FirebaseAuthType } from 'src/compositions/firebase'

const name = 'auth-btn'

export default defineComponent({
  name,
  components: {
    QBtn,
    QIcon
  },
  props: {
    authType: {
      type: String as PropType<FirebaseAuthType | 'EMAIL'>,
      required: true
    },
    disable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    VueClick
  ],
  setup(props, { emit }) {
    const { tp } = useI18n$(name)

    const authType = toRef(props, 'authType')
    const alt = computed(() => {
      switch (authType.value) {
        case 'GOOGLE':
          return 'Google'
        case 'APPLE':
          return 'Apple'
        case 'EMAIL':
          return 'Email'
      }
    })

    return {
      alt,
      tp,
      click: () => emit(VueClick)
    }
  }
})
