import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["i18n"]
const _hoisted_2 = { class: "row justify-end" }
const _hoisted_3 = { class: "col-12 col-md-10 col-lg-8 text-center" }
const _hoisted_4 = { class: "q-pb-xl" }
const _hoisted_5 = { class: "row items-center justify-center items-stretch" }
const _hoisted_6 = { class: "col-10 col-sm-6 col-xl-4 app-links-banner__button-left" }
const _hoisted_7 = { class: "row col-12 items-center q-pa-md" }
const _hoisted_8 = { class: "col-auto q-pr-sm" }
const _hoisted_9 = {
  width: "29",
  height: "29",
  viewBox: "0 0 29 29",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  "svg-inline": '',
  role: 'presentation',
  focusable: 'false',
  tabindex: '-1'
}
const _hoisted_10 = { class: "col text-left" }
const _hoisted_11 = { class: "col-10 col-sm-6 col-xl-4 app-links-banner__button-right" }
const _hoisted_12 = { class: "row col-12 items-center q-pa-md" }
const _hoisted_13 = { class: "col-auto q-pr-sm" }
const _hoisted_14 = {
  width: "29",
  height: "29",
  viewBox: "0 0 29 29",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  "svg-inline": '',
  role: 'presentation',
  focusable: 'false',
  tabindex: '-1'
}
const _hoisted_15 = { class: "col text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_banner_template = _resolveComponent("banner-template")!

  return (_openBlock(), _createElementBlock("div", {
    i18n: _ctx.$options.name,
    class: "fit app-links-banner q-pt-lg"
  }, [
    _createVNode(_component_banner_template, {
      alt: _ctx.alt,
      large: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", {
              class: _normalizeClass([{'text-h2': _ctx.$q.screen.gt.xs, 'text-h3': _ctx.$q.screen.xs}, "q-pb-md"])
            }, _toDisplayString(_ctx.tp('title')), 3),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.tp('subtitle')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_btn, {
                  href: _ctx.getAppStoreLink(_ctx.language),
                  class: "text-transform-none fit",
                  color: "white",
                  outline: "",
                  padding: "none",
                  size: "md",
                  type: "a"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        (_openBlock(), _createElementBlock("svg", _hoisted_9, _cache[0] || (_cache[0] = [
                          _createElementVNode("path", {
                            d: "M22.446 16.255c-.797-4.014 3.013-6.37 3.013-6.37s-1.484-2.147-3.98-2.7c-2.494-.553-3.742.138-4.988.657-1.246.52-1.869.52-1.869.52-1.8 0-3.115-1.905-6.543-1.037-2.362.596-4.849 3.323-5.298 6.577-.45 3.255.52 7.41 2.39 10.63 1.869 3.221 3.773 4.432 5.124 4.467 1.352.033 2.7-.968 4.327-1.212 1.628-.242 2.63.59 4.227 1.039 1.59.448 2.148.032 3.98-1.526 1.836-1.558 3.499-6.027 3.499-6.027s-3.084-1-3.882-5.018zM18.915 4.588C20.682 2.788 20.083 0 20.083 0s-2.481.436-4.144 2.165c-1.661 1.732-1.386 4.466-1.386 4.466s2.594-.243 4.362-2.043z",
                            fill: "#fff"
                          }, null, -1)
                        ])))
                      ]),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.tp('appStore')), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["href"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_q_btn, {
                  href: _ctx.GOOGLE_PLAY_LINK,
                  class: "text-transform-none fit",
                  color: "white",
                  outline: "",
                  padding: "none",
                  size: "md",
                  type: "a"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        (_openBlock(), _createElementBlock("svg", _hoisted_14, _cache[1] || (_cache[1] = [
                          _createElementVNode("g", {
                            "clip-path": "url(#clip0_475_892)",
                            fill: "#FFFDFD"
                          }, [
                            _createElementVNode("path", { d: "M4.026 9.394c-.5 0-.924.174-1.272.523a1.711 1.711 0 00-.523 1.254v7.494c0 .5.174.924.523 1.273.348.348.772.523 1.272.523s.92-.175 1.264-.523c.342-.349.514-.772.514-1.273v-7.494a1.71 1.71 0 00-.523-1.254 1.712 1.712 0 00-1.255-.523zM18.456 2.666L19.694.384c.08-.151.052-.267-.087-.349-.151-.07-.268-.034-.349.105l-1.255 2.3a8.568 8.568 0 00-3.503-.732c-1.231 0-2.4.244-3.503.733L9.743.14C9.66 0 9.545-.035 9.394.035c-.14.082-.168.198-.087.349l1.237 2.282c-1.254.64-2.254 1.531-2.997 2.676A6.757 6.757 0 006.43 9.098h16.12c0-1.36-.371-2.61-1.115-3.756a7.674 7.674 0 00-2.98-2.676zm-7.154 3.32a.653.653 0 01-.48.202.624.624 0 01-.47-.201.668.668 0 01-.191-.48.67.67 0 01.191-.479.626.626 0 01.47-.2c.187 0 .346.067.48.2.134.135.2.294.2.48a.656.656 0 01-.2.479zm7.346 0a.625.625 0 01-.47.202.653.653 0 01-.48-.201.652.652 0 01-.2-.48c0-.185.066-.345.2-.479a.654.654 0 01.48-.2c.185 0 .342.067.47.2a.67.67 0 01.191.48.668.668 0 01-.191.479zM6.5 21.332c0 .535.187.988.558 1.36a1.85 1.85 0 001.36.557h1.29l.017 3.957c0 .5.174.923.523 1.272.348.348.767.523 1.254.523.5 0 .924-.175 1.272-.523.35-.349.523-.773.523-1.272v-3.957h2.405v3.957c0 .5.175.923.523 1.272.349.348.772.523 1.272.523s.924-.175 1.272-.523c.349-.349.523-.773.523-1.272v-3.957h1.307c.523 0 .97-.185 1.342-.557a1.85 1.85 0 00.558-1.36V9.726H6.5v11.607zM24.974 9.394c-.488 0-.906.172-1.255.514a1.704 1.704 0 00-.522 1.263v7.494c0 .5.174.924.523 1.273.348.348.767.523 1.254.523.5 0 .924-.175 1.272-.523a1.73 1.73 0 00.523-1.273v-7.494c0-.499-.174-.92-.523-1.263a1.748 1.748 0 00-1.272-.514z" })
                          ], -1),
                          _createElementVNode("defs", null, [
                            _createElementVNode("clipPath", { id: "clip0_475_892" }, [
                              _createElementVNode("path", {
                                fill: "#fff",
                                d: "M0 0h29v29H0z"
                              })
                            ])
                          ], -1)
                        ])))
                      ]),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.tp('googlePlay')), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["href"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["alt"])
  ], 8, _hoisted_1))
}