import { Coordinates, Currency } from 'src/model/common.model'
import { Language } from 'src/model/language.model'

export const SITE_URL: string = process.env.URL as string
export const APP_ANDROID_URL: string = 'https://app.qeepl.com'
export const APP_IOS_URL: string = 'capacitor://app.qeepl.com'
export const APP: boolean = process.env.MODE === 'capacitor'
export const SSR: boolean = process.env.SERVER as unknown as boolean
export const PROD: boolean = process.env.PROD as unknown as boolean
export const ENV: string = process.env.NAME as unknown as string
export const ALLOWED_LANGUAGES: Language[] = process.env.ALLOWED_LANGUAGES!.split(',') as unknown as Language[]
export const STRIPE_KEY: string = process.env.STRIPE_KEY as unknown as string
export const RECAPTCHA_TOKEN: string = process.env.RECAPTCHA_TOKEN as unknown as string
export const FIREBASE_WEB_CONFIG: object = JSON.parse(process.env.FIREBASE_WEB_CONFIG as unknown as string)
export const SELF_ORDER_MAX_FUTURE_BOOKING_TIME_DAYS: number = parseInt(process.env.SELF_ORDER_MAX_FUTURE_BOOKING_TIME_DAYS as string)
export const USER_VERIFICATION: { environmentId: string; templateId: string } = JSON.parse(process.env.USER_VERIFICATION as string)

export const TOKEN_STORE_NAME = '__qeepl-jwt__'
export const DISCOUNT_STORE_NAME = '__qeepl-discount__'
export const ORDER_STORE_NAME = '__qeepl-order__'
export const APP_REVIEW_LEFT_STORE_NAME = '__qeepl-app_review_left__'
export const FIREBASE_TOKEN_STORE_NAME = '__firebase_token__'
export const CITY_ADS_CLICK_ID_STORE_NAME = '__qeepl_city_ads_click_id__'

export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_USER = 'ROLE_USER'
export const ROLE_USER_OF_COMPANY = 'ROLE_USER_OF_COMPANY'
export const ROLE_MASTER_USER_OF_COMPANY = 'ROLE_MASTER_USER_OF_COMPANY'
export const ROLE_ONBOARDING_USER_OF_COMPANY = 'ROLE_ONBOARDING_USER_OF_COMPANY'
export const ROLE_ADMIN_SECONDARY = /(ROLE_ADMIN)[_A-Z]+/

export const INSTAGRAM_URL = 'https://www.instagram.com/qeepl_official'
export const TWITTER_URL = 'https://twitter.com/qeepluggage'
export const FACEBOOK_URL = 'https://facebook.com/Qeepl'
export const LINKEDIN_URL = 'https://linkedin.com/company/qeepl'
export const TELEGRAM_URL = 'https://t.me/qeepl_storage'
export const PHONE = '88005118930'
export const EMAIL = 'support@qeepl.com'
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.qeepl'
export const RU_TIPS_LINK = 'https://netmonet.co/tip/225240?o=0'
export const OTHER_TIPS_LINK = 'https://tpjr.us/qeepl'
export const IMGKIT_URL = 'https://ik.imagekit.io/f8xal2viyc0'
export const TELEGRAM_PARTNER_GROUP = 'https://t.me/+nG-VmdZ7JIRmMGJi'
export const FACEBOOK_PARTNER_GROUP = 'https://www.facebook.com/groups/1920372861811530/'

export const DEFAULT_LOGO = '/images/logo.svg'
export const DEFAULT_LOGO_512 = '/images/logo_512x512.svg'
export const DEFAULT_COUNTRY_KEY = 'US'
export const DEFAULT_CITY_KEY = 'NEW_YORK'
export const DEFAULT_COORDINATES: Coordinates = { latitude: 40.71427, longitude: -74.00597 }
export const DEFAULT_CURRENCY: Currency = 'USD'
export const DEFAULT_MONTH_PRICE = 25
export const DEFAULT_DAY_PRICE = 4.9
export const DEFAULT_HOUR_PRICE = 1.5
export const DEFAULT_SMALL_MONTH_PRICE = 15
export const DEFAULT_SMALL_DAY_PRICE = 3.5
export const DEFAULT_SMALL_HOUR_PRICE = 1
export const DEFAULT_LARGE_MONTH_PRICE = 40
export const DEFAULT_LARGE_DAY_PRICE = 6.9
export const DEFAULT_LARGE_HOUR_PRICE = 2.5
export const DEFAULT_INSURANCE = 1000
export const DEFAULT_RADIUS_IN_KM = 15
export const DEFAULT_COMPANY_RADIUS_IN_M = 100
export const DEFAULT_TIMEZONE = 'US/Eastern'
export const DEFAULT_MAX_DESTINATION_TO_CITY_IN_KM = 30
export const DEFAULT_MAX_DESTINATION_TO_SHOW_RELOAD_BUTTON_IN_KM = 2
export const MAX_ACCURACY_RADIUS = 125
export const CHECKING_INTERVAL_MS: number = 30 * 60 * 1000
export const OUT_OF_WORLD_COUNTRIES = ['RU', 'BY']
export const KM_TO_MI_MULTIPLIER = 0.621371
export const M_TO_FT_MULTIPLIER = 3.28084
export const FT_TO_MI_MULTIPLIER = 5280
export const SMALL_BAG_FACTOR = 0.75
export const LARGE_BAG_FACTOR = 1.5

export const COMPANY_LUGGAGE_FALLBACK_IMG = `${ IMGKIT_URL }/System/defaults/luggage.png`
export const COMPANY_SELF_FALLBACK_IMG = `${ IMGKIT_URL }/System/defaults/self.png`
export const CITY_FALLBACK_IMG = '/images/city-fallback.jpg'
export const ATTRACTION_FALLBACK_IMG = '/images/attraction-fallback.jpg'

export const IGNORE_AJAX_URLS = ['https://mc.yandex.ru']
